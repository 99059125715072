<template>
    <div class="item">
        <div class="item-title-group">
            <div class="item-title">{{ title }}</div>
            <div class="item-action"><slot name="action"></slot></div>
        </div>
        <slot name="main"></slot>
        <div v-if="loading" class="spinner-flex"><Spinner size="small"/></div>
    </div>
</template>

<script>
    export default {
        props: ['title', 'loading']
    }
</script>

<style lang="scss" scoped>

.spinner-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: rgba(255,255,255,0.5);
    z-index: 3;
    border-radius: 20px;
}

.item {
    padding: 20px;
    border: 1px solid $borderColor;
    box-shadow: $boxShadow;
    background: $bgShade;
    border-radius: 20px;
    display: grid;
    gap: 20px;
    position: relative;
    .item-title-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .item-title {
        font-weight: 500;
        font-size: 13px;
    }
    .item-action {
        // position: absolute;
        // top: 20px;
        // right: 20px;
        // z-index: 3;
    }
}
</style>