<template>
  <div class="modal-wrapper">
    <Input
      name="Year"
      placeholder="Year"
      v-model="details.year"
      :error="errors.year"
    />
    <Input
      name="Sheet Name"
      placeholder="Sheet Name"
      v-model="details.sheetName"
      :error="errors.sheetName"
    />
    <Input
      name="Sheet ID"
      placeholder="Sheet ID"
      v-model="details.sheetId"
      :error="errors.sheetId"
    />
  </div>
</template>

<script>
import Helper from "../../../mixins/ConsolidationSettings/Helper";

export default {
  mixins: [Helper],
  props: ["modal"],
  data() {
    return {
      details: {},
      errors: {},
    };
  },
  methods: {},
  mounted() {
    this.modal.onConfirm(async () => {
      this.errors = {};
      this.modal.processing(true);
      const { year, sheetId, sheetName } = this.details;
      await this.consolidation_settings_add_year_details(
        { year, sheetId, sheetName },
        (err, body) => {
          if (err) {
            if (body.errors) this.errors = body.errors;
            else
              alert(body.message || "Something went wrong. Please, try again!");
          } else {
            // close modal...
            this.modal.close();
          }
        }
      );
      this.modal.processing(false);
    });
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  padding: 20px;
  display: grid;
  gap: 10px;
}
</style>
