<template>
  <div style="display: grid; gap: 30px">
    <DashboardItem title="Total LTL">
      <template v-slot:main>
        <div class="total-ltl-grid">
          <div>
            <Table
              :head="[
                { name: '' },
                { name: 'Name' },
                { name: 'Amount' },
                { name: 'Target' },
              ]"
              :stickyHead="true"
              :size="[
                '40px',
                'minmax(0, 1fr)',
                'minmax(100px, 150px)',
                '100px',
              ]"
            >
              <TransitionGroup name="list">
                <TotalLtlItem
                  v-for="(item, index) of sortedStats"
                  :key="item.name"
                  :item="item"
                  :target="dispatchersTargets.obj[item.name]"
                  :index="index"
                  @setTarget="setDispatchTarget"
                  :class="[
                    {
                      'top-dispatcher': index <= 2,
                      'worst-dispatcher': index >= sortedStats.length - 2,
                    },
                  ]"
                />
              </TransitionGroup>
            </Table>
          </div>

          <div class="graph-table">
            <TransitionGroup name="list">
              <div
                v-for="(item, index) of targetStats"
                :key="item.name"
                :item="item"
                :target="dispatchersTargets.obj[item.name]"
                class="row"
              >
                <div class="name-holder">
                  <div>{{ item.name }}</div>
                  <!-- <div>
                    {{ CURRENCY.format(item.amount) }} /
                    {{ CURRENCY.format(item.target) }}
                  </div> -->
                </div>
                <div>
                  <div class="bar-percent-holder">
                    <div class="bar-holder">
                      <div class="bar" :style="`width: ${item.percent}%`"></div>
                    </div>
                    <div class="percent" :style="`left: ${item.percent}%`">
                      {{ PERCENTAGE.format(item.percent / 100) }}
                    </div>
                  </div>
                </div>
              </div>
            </TransitionGroup>
            <!-- <ColumnsChartHorizontal
              :series="[{ name: 'Total LTL', data: statsSeries }]"
              :labels="statsLabels"
              :labelFormat="formatPrice"
              :yLabelFormat="formatPrice"
              height="auto"
              :key="statsLabels"
            /> -->
          </div>
        </div>
      </template>
    </DashboardItem>
  </div>
</template>

<script>
import ColumnsChartHorizontal from "../ApexCharts/ColumnsChartHorizontal.vue";
import DashboardItem from "../DashboardItem.vue";
import ColumnsChart from "../ApexCharts/ColumnsChart.vue";
import TotalLtlItem from "./TotalLtlItem.vue";
import Consolidation from "../../mixins/Consolidation";
import UI_Consolidation from "../../mixins/UI_Consolidation";
import StatsTarget from "./StatsTarget.vue";

export default {
  mixins: [Consolidation, UI_Consolidation],
  components: {
    ColumnsChartHorizontal,
    DashboardItem,
    ColumnsChart,
    TotalLtlItem,
    StatsTarget,
  },
  props: ["stats", "period", "targets"],
  data() {
    return {
      dispatchersTargets: {
        arr: [],
        obj: {},
      },
    };
  },
  watch: {
    period: {
      deep: true,
      handler(to, from) {
        if (to.year !== from.year || to.month !== from.month)
          this.getDispatchersTargets();
      },
    },
  },
  mounted() {
    this.getDispatchersTargets();

    this.$WebSocket.on("open", "consolidation-total-ltl-component", () => {
      this.getDispatchersTargets();
    });

    this.$WebSocket.on(
      "/consolidation/dispatchers-targets/update",
      "consolidation-total-ltl-component",
      () => {
        this.getDispatchersTargets();
      }
    );
  },
  methods: {
    setMonthlyTarget(target, type) {
      this.ui_consolidation_set_monthly_target(
        target,
        this.period.year,
        this.period.month,
        type,
        (err, body) => {
          if (!err) {
            this.$emit("updateTargets");
          }
        }
      );
    },
    setDispatchTarget({ user, target }) {
      this.ui_consolidation_set_dispatch_target(
        target,
        this.period.year,
        this.period.month,
        user,
        (err, body) => {
          if (!err) {
            this.getDispatchersTargets();
          }
        }
      );
    },
    formatPrice(val) {
      return this.CURRENCY.format(val);
    },
    getDispatchersTargets() {
      this.consolidation_get_dispatchers_targets(
        this.period.year,
        this.period.month,
        (err, body) => {
          if (!err) {
            this.dispatchersTargets = body;
          }
        }
      );
    },
  },
  computed: {
    statsSeries() {
      return this.sortedStats.map((item) => item.amount);
    },
    statsLabels() {
      return this.sortedStats.map((item) => item.name);
    },
    sortedStats() {
      const data = JSON.parse(JSON.stringify(this.stats.data));
      return data.sort((a, b) => b.amount - a.amount);
    },
    targetStats() {
      const data = JSON.parse(JSON.stringify(this.stats.data));
      return data
        .map((row) => {
          let percent = 0;
          const target = this.dispatchersTargets.obj[row.name];

          if (target && target.target) {
            try {
              percent = (row.amount * 100) / target.target;
            } catch (e) {}
          }

          return {
            name: row.name,
            amount: row.amount,
            percent: percent,
            target: target && target.target ? target.target : null,
          };
        })
        .sort((a, b) => b.percent - a.percent);
    },
  },
};
</script>

<style lang="scss" scoped>
.graph-table {
  border-radius: 9px;
  overflow: hidden;
  box-shadow: $boxShadow;
  border: 1px solid $borderColor;
  .row {
    padding: 10px;
    display: grid;
    grid-template-columns: 100px minmax(0, 1fr);
    gap: 10px;
    align-items: center;
    align-content: center;
    background: $bg;
    &:nth-child(2n) {
      background: transparent;
    }
    .name-holder {
      border-right: 1px solid $borderColor;
      font-size: 14px;
      text-transform: capitalize;
    }
    .bar-percent-holder {
      display: grid;
      grid-template-columns: minmax(0, 1fr) 50px;
      align-items: center;
      align-content: center;
      gap: 10px;
      .percent {
      }
    }
    .bar-holder {
      width: 100%;
      position: relative;
      border-radius: 6px;
      overflow: hidden;
      height: 12px;
      background: rgba(0, 0, 0, 0.1);
      .bar {
        position: absolute;
        height: 100%;
        width: 0;
        background: $themeColor2;
        transition: ease 0.3s;
        border-radius: 4px;
      }
    }
  }
}

.total-ltl-grid {
  width: 100%;
  display: grid;
  align-items: start;
  gap: 50px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}

.top-dispatcher {
  background: #abebc6;
  &:hover {
    background: #abebc6 !important;
  }
}

.worst-dispatcher {
  background: #f5b7b1;
  &:hover {
    background: #f5b7b1 !important;
  }
}
</style>
