<template>
  <StatsItem
    :text="title"
    icon="fa-solid fa-bullseye"
    :horizontal="false"
    :loading="isLoading(['Stats-GetTarget'])"
    :autoHeight="true"
  >
    <template v-slot:main>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <SemiCircle
          :series="[
            Number(
              target && target.target && target.target.percent
                ? target.target.percent
                : 0
            ).toFixed(2),
          ]"
          :colors="[
            target && target.target && target.target.percent
              ? target.target.percent <= 70
                ? '#CD6155'
                : target.target.percent <= 90
                ? '#F1C40F'
                : '#17A589'
              : '#CD6155',
          ]"
        />

        <div class="target-info">
          <a href="" @click.prevent="$emit('setTarget')"
            >{{ CURRENCY.format(target.total) }} /
            {{
              CURRENCY.format(
                target.target && target.target.target ? target.target.target : 0
              )
            }}</a
          >
          <div
            v-if="target && target.target && target.target.percent >= 100"
            class="target-completed-text"
          >
            Congrats! You smashed it
          </div>
          <div v-else class="target-completed-text">
            <span style="font-weight: 600">{{
              CURRENCY.format(target && target.target ? target.target.left : 0)
            }}</span>
            left to go
          </div>
        </div>
      </div>
    </template>
  </StatsItem>
</template>

<script>
import StatsItem from "../StatsItem.vue";
import SemiCircle from "../ApexCharts/SemiCircle.vue";

export default {
  components: {
    StatsItem,
    SemiCircle,
  },
  props: ["target", "title"],
};
</script>

<style lang="scss" scoped>
.target-info {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}

.target-completed-text {
  color: $themeColor2;
  font-size: 14px;
  font-weight: 400;
}
</style>
