<template>
  <div class="modal-wrapper">
    <Input name="Year" placeholder="Year" v-model="details.year" disabled />
    <Input
      name="Sheet Name"
      placeholder="Sheet Name"
      v-model="details.sheetName"
      :error="errors.sheetName"
    />
    <Input
      name="Sheet ID"
      placeholder="Sheet ID"
      v-model="details.sheetId"
      :error="errors.sheetId"
    />
    <div style="margin-top: 10px">
      <Button
        size="small"
        theme="white"
        @click="ui_consolidation_settings_edit_year_months(this.year)"
        >Edit months</Button
      >
    </div>
  </div>
</template>

<script>
import Helper from "../../../mixins/ConsolidationSettings/Helper";
import UI_EditYearMonths from "../../../mixins/ConsolidationSettings/UI_EditYearMonths";
export default {
  mixins: [Helper, UI_EditYearMonths],
  props: ["year", "modal"],
  data() {
    return {
      details: {},
      errors: {},
    };
  },
  methods: {
    async getDetails() {
      this.modal.processing(true);
      await this.consolidation_settings_get_year_details(
        this.year,
        (err, body) => {
          if (err) {
            this.modal.close();
          } else {
            this.details = body;
          }
        }
      );
      this.modal.processing(false);
    },
  },
  mounted() {
    this.getDetails();
    this.modal.onConfirm(async () => {
      this.errors = {};
      this.modal.processing(true);
      const { year, sheetId, sheetName } = this.details;
      await this.consolidation_settings_update_year_sheet_details(
        this.year,
        { year, sheetId, sheetName },
        (err, body) => {
          if (err) {
            if (body.errors) this.errors = body.errors;
            else
              alert(body.message || "Something went wrong. Please, try again!");
          } else {
            // close modal...
            this.modal.close();
          }
        }
      );
      this.modal.processing(false);
    });
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  padding: 20px;
  display: grid;
  gap: 10px;
}
</style>
