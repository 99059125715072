import AvailableYearsModal from "../../components/Consolidation/settings/AvailableYears";

export default {
  methods: {
    ui_consolidation_settings_available_years() {
      // show ui of available years...
      let modal = this.$ShowModal({
        title: `Settings`,
        component: AvailableYearsModal,
        width: "792px",
        confirm: "Add year",
        cancel: "Close",
      });

      // modal.onConfirm(async (props) => {
      //   modal.close();
      // });
    },
  },
};
