<template>
  <div class="modal-wrapper">
    <Input
      v-model="userTarget"
      name="Target"
      :error="errors.amount"
      placeholder="Enter target"
    />
  </div>
</template>

<script>
export default {
  props: ["target", "modal", "errors"],
  data() {
    return {
      userTarget: this.target || "",
    };
  },
  watch: {
    userTarget() {
      this.modal.setProp("target", this.userTarget);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  padding: 20px;
  display: grid;
  gap: 20px;
}
</style>
