import AddYear from "../../components/Consolidation/settings/AddYear";

export default {
  methods: {
    ui_consolidation_settings_add_year() {
      // show ui of available years...
      let modal = this.$ShowModal({
        title: `Add`,
        component: AddYear,
        preventFromClosing: true,
      });
    },
  },
};
