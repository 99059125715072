import EditYear from "../../components/Consolidation/settings/EditYear";

export default {
  methods: {
    ui_consolidation_settings_edit_year(year = 2024) {
      // show ui of available years...
      let modal = this.$ShowModal({
        title: `Edit`,
        component: EditYear,
        preventFromClosing: true,
        props: {
          year,
        },
      });
    },
  },
};
