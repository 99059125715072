<template>
  <div class="stats-item" :class="[{ horizontal: horizontal }]">
    <div class="header">
      <div class="icon-holder" v-if="icon">
        <span><i :class="icon || 'fa-solid fa-dollar-sign'"></i></span>
      </div>
      <div class="text">{{ text }}</div>
    </div>

    <div class="content-item">
      <div class="stats-content" :class="[{ 'auto-height': autoHeight }]">
        <slot name="main"></slot>
      </div>
    </div>
    <div class="stats-action"><slot name="action"></slot></div>
    <div v-if="loading" class="spinner-flex"><Spinner size="small" /></div>
  </div>
</template>

<script>
export default {
  props: ["icon", "text", "loading", "horizontal", "autoHeight"],
};
</script>

<style lang="scss" scoped>
.stats-item {
  padding: 20px;
  background: $themeColor1;
  border-radius: 20px;
  display: grid;
  row-gap: 20px;
  position: relative;
  border: 1px solid $borderColor;
  box-shadow: $boxShadow;
  .icon-holder {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $bg;
    font-size: 18px;
  }
  .header {
    display: flex;
    align-items: center;
    gap: 20px;
    .text {
      color: $textShade;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      font-weight: 500;
    }
  }
  .content-item {
    display: grid;
  }
  .stats-content {
    font-size: 32px;
    font-weight: 300;
    height: 70px;
    &.auto-height {
      height: auto;
    }
  }
  &.horizontal {
    grid-template-columns: 50px minmax(0, 1fr);
    align-items: center;
    gap: 20px;
    padding: 20px;
    .stats-content {
      height: auto;
      font-size: 28px;
    }
  }
}

.stats-action {
  position: absolute;
  top: 20px;
  right: 20px;
}

.spinner-flex {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 3;
  border-radius: 20px;
}
</style>
