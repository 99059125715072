export default {
  methods: {
    async consolidation_settings_get_years(callback) {
      await this.ajax(
        "Consolidation-Settings-GetAvailalbeYears",
        {
          method: "GET",
          url: `/consolidation/settings/years`,
        },
        callback
      );
    },
    async consolidation_settings_get_year_details(year = 2024, callback) {
      await this.ajax(
        "Consolidation-Settings-GetYearDetails",
        {
          method: "GET",
          url: `/consolidation/settings/years/${year}`,
        },
        callback
      );
    },
    async consolidation_settings_update_year_sheet_details(
      year = 2024,
      data = {},
      callback
    ) {
      await this.ajax(
        "Consolidation-Settings-UpdateYearSheetDetails",
        {
          method: "PUT",
          url: `/consolidation/settings/years/${year}`,
          data,
        },
        callback
      );
    },
    async consolidation_settings_update_year_details(
      year = 2024,
      data = {},
      callback
    ) {
      await this.ajax(
        "Consolidation-Settings-UpdateYearDetails",
        {
          method: "PUT",
          url: `/consolidation/settings/years/${year}/months`,
          data,
        },
        callback
      );
    },
    async consolidation_settings_add_year_details(data = {}, callback) {
      await this.ajax(
        "Consolidation-Settings-AddYearDetails",
        {
          method: "POST",
          url: `/consolidation/settings/years`,
          data,
        },
        callback
      );
    },
  },
};
