import EditYearMonths from "../../components/Consolidation/settings/EditYearMonths";

export default {
  methods: {
    ui_consolidation_settings_edit_year_months(year = 2024) {
      // show ui of available years...
      let modal = this.$ShowModal({
        title: `Edit`,
        component: EditYearMonths,
        preventFromClosing: true,
        props: {
          year,
        },
      });
    },
  },
};
