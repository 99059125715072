<template>
  <div style="display: grid; gap: 30px">
    <div class="total-ltl-grid" style="gap: 30px" v-if="targets">
      <StatsTarget
        :target="targets ? targets.ltl : null"
        title="LTl Target"
        v-if="targets && targets.ltl"
        @setTarget="
          () => {
            setMonthlyTarget(
              targets.ltl.target && targets.ltl.target.target
                ? targets.ltl.target.target
                : 0,
              'ltl'
            );
          }
        "
      />
      <StatsTarget
        :target="targets ? targets.gross : null"
        title="Gross Target"
        v-if="targets && targets.gross"
        @setTarget="
          () => {
            setMonthlyTarget(
              targets.gross.target && targets.gross.target.target
                ? targets.gross.target.target
                : 0,
              'gross'
            );
          }
        "
      />
    </div>
  </div>
</template>

<script>
import ColumnsChartHorizontal from "../ApexCharts/ColumnsChartHorizontal.vue";
import DashboardItem from "../DashboardItem.vue";
import ColumnsChart from "../ApexCharts/ColumnsChart.vue";
import TotalLtlItem from "./TotalLtlItem.vue";
import Consolidation from "../../mixins/Consolidation";
import UI_Consolidation from "../../mixins/UI_Consolidation";
import StatsTarget from "./StatsTarget.vue";
import { mapGetters } from "vuex";

export default {
  mixins: [Consolidation, UI_Consolidation],
  components: {
    StatsTarget,
  },
  props: ["targets", "period"],
  computed: {
    ...mapGetters(["adminToken"]),
  },
  methods: {
    setMonthlyTarget(target, type) {
      if (!this.adminToken) return;
      this.ui_consolidation_set_monthly_target(
        target,
        this.period.year,
        this.period.month,
        type,
        (err, body) => {
          if (!err) {
            this.$emit("updateTargets");
          }
        }
      );
    },
    formatPrice(val) {
      return this.CURRENCY.format(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.graph-table {
  border-radius: 9px;
  overflow: hidden;
  box-shadow: $boxShadow;
  border: 1px solid $borderColor;
  .row {
    padding: 10px;
    display: grid;
    grid-template-columns: 100px minmax(0, 1fr);
    gap: 10px;
    align-items: center;
    align-content: center;
    background: $bg;
    &:nth-child(2n) {
      background: transparent;
    }
    .name-holder {
      border-right: 1px solid $borderColor;
      font-size: 14px;
      text-transform: capitalize;
    }
    .bar-percent-holder {
      display: grid;
      grid-template-columns: minmax(0, 1fr) 50px;
      align-items: center;
      align-content: center;
      gap: 10px;
      .percent {
      }
    }
    .bar-holder {
      width: 100%;
      position: relative;
      border-radius: 6px;
      overflow: hidden;
      height: 12px;
      background: rgba(0, 0, 0, 0.1);
      .bar {
        position: absolute;
        height: 100%;
        width: 0;
        background: $themeColor2;
        transition: ease 0.3s;
        border-radius: 4px;
      }
    }
  }
}

.total-ltl-grid {
  width: 100%;
  display: grid;
  align-items: start;
  gap: 50px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}

.top-dispatcher {
  background: #abebc6;
  &:hover {
    background: #abebc6 !important;
  }
}

.worst-dispatcher {
  background: #f5b7b1;
  &:hover {
    background: #f5b7b1 !important;
  }
}
</style>
