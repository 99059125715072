<template>
  <!-- <div class="chart" id="chart" ref="chart"></div> -->
  <ApexChart
    type="bar"
    :height="height || 350"
    :options="_options"
    :series="series"
  ></ApexChart>
</template>

<script>
import ApexCharts from "apexcharts";

export default {
  props: [
    "height",
    "width",
    "chart",
    "series",
    "labels",
    "labelFormat",
    "sparkline",
    "yLabelFormat",
  ],
  data() {
    return {
      _options: {
        chart: {
          height: this.height || 350,
          type: "bar",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: this.sparkline ? this.sparkline : false,
          },
        },
        legend: {
          show: false,
        },
        stroke: {
          width: [0],
          colors: ["#fff"],
        },
        labels: this.labels,
        fill: {
          colors: ["#4653FF"],
        },
        colors: ["#4653FF"],
        plotOptions: {
          bar: {
            borderRadius: 3,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        yaxis: {
          show: false,
          labels: {
            formatter: (val) => {
              if (this.yLabelFormat) return this.yLabelFormat(val);
              return val;
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (val) => {
            if (this.labelFormat) return this.labelFormat(val);
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "9px",
            colors: ["#404040"],
          },
        },
        grid: {
          show: true, // you can either change hear to disable all grids
          borderColor: "rgba(0,0,0,0.05)",
          xaxis: {
            lines: {
              show: false, //or just here to disable only x axis grids
            },
          },
          yaxis: {
            lines: {
              show: true, //or just here to disable only y axis
            },
          },
        },
      },
    };
  },
  mounted() {
    let that = this;

    var options = {
      series: this.series,
      chart: {
        height: this.height || 350,
        type: "bar",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: that.sparkline ? that.sparkline : false,
        },
      },
      legend: {
        show: false,
      },
      stroke: {
        width: [0],
        colors: ["#fff"],
      },
      labels: this.labels,
      fill: {
        colors: ["#4653FF"],
      },
      colors: ["#4653FF"],
      yaxis: {
        show: true,
        crosshairs: {
          show: true,
          stroke: {
            width: 1,
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            if (that.yLabelFormat) return that.yLabelFormat(val);
            return val;
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          if (that.labelFormat) return that.labelFormat(val);
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "9px",
          colors: ["#404040"],
        },
      },
    };

    // var chart = new ApexCharts(this.$refs['chart'], options);
    // chart.render();
  },
};
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
}
</style>
