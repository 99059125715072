<template>
  <ApexChart
    type="radialBar"
    :height="height || 350"
    :options="_options"
    :series="series"
  ></ApexChart>
</template>

<script>
export default {
  props: [
    "height",
    "width",
    "chart",
    "series",
    "labels",
    "labelFormat",
    "sparkline",
    "yLabelFormat",
    "colors",
  ],
  computed: {
    _options() {
      return {
        chart: {
          type: "radialBar",
          offsetY: 0,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          lineCap: "round",
        },
        states: {
          hover: {
            filter: {
              type: "darken",
              value: 0.8,
            },
          },
          active: {
            filter: {
              type: "none" /* none, lighten, darken */,
            },
          },
        },
        // labels: ["$40,700.00 / $54,200.00"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "70%",
            },
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#eee",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: "22px",
              },
            },
          },
        },
        fill: {
          colors: this.colors || ["#5864FF"],
        },
        grid: {
          padding: {
            top: -10,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
}
</style>
