import TargetModal from "../components/Consolidation/TargetModal";

import Consolidation from "./Consolidation";

export default {
  mixins: [Consolidation],
  methods: {
    ui_consolidation_set_dispatch_target(
      currentTarget = "0",
      year,
      month,
      userId,
      callback
    ) {
      let modal = this.$ShowModal({
        title: `Set target`,
        component: TargetModal,
        width: "492px",
        props: {
          target: currentTarget,
          errors: {},
        },
      });

      modal.onConfirm(async (props) => {
        modal.processing(true);
        await this.consolidation_set_dispatchers_target(
          year,
          month,
          userId,
          props.target,
          (err, body) => {
            if (!err) {
              modal.close();
            } else {
              if (body.errors) modal.setProp("errors", body.errors);
              else
                alert(
                  body.message || "Something went wrong. Please, try again!"
                );
            }
            callback(err, body);
          }
        );
        modal.processing(false);
      });
    },
    ui_consolidation_set_monthly_target(
      currentTarget = "0",
      year,
      month,
      type,
      callback
    ) {
      let modal = this.$ShowModal({
        title: `Set target`,
        component: TargetModal,
        width: "492px",
        props: {
          target: currentTarget,
          errors: {},
        },
      });

      modal.onConfirm(async (props) => {
        modal.processing(true);
        await this.consolidation_set_monthly_target(
          year,
          month,
          type,
          props.target,
          (err, body) => {
            if (!err) {
              modal.close();
            } else {
              if (body.errors) modal.setProp("errors", body.errors);
              else
                alert(
                  body.message || "Something went wrong. Please, try again!"
                );
            }
            callback(err, body);
          }
        );
        modal.processing(false);
      });
    },
  },
};
