<template>
  <div class="wrapper">
    <div style="display: grid; gap: 20px">
      <div
        style="
          display: flex;
          gap: 20px;
          align-items: center;
          justify-content: space-between;
        "
      >
        <Transition
          name="fade"
          mode="out-in"
          :duration="{ enter: 500, leave: 1 }"
        >
          <h2 :key="currentSlide.id">{{ currentSlide.name }}</h2>
        </Transition>

        <div style="display: flex; gap: 10px; align-items: center">
          <!-- Month selector -->
          <div class="target-month-switcher">
            <button class="switch" @click="settingsSwitchMonth(-1)">
              <i class="fa-solid fa-chevron-left"></i>
            </button>
            <DropDownDatePicker
              position="right"
              v-model="settings.date"
              displayFormat="MMMM, YYYY"
            />
            <button class="switch" @click="settingsSwitchMonth(1)">
              <i class="fa-solid fa-chevron-right"></i>
            </button>
          </div>

          <!-- Admin settings -->
          <button
            class="manage-btn"
            @click="ui_consolidation_settings_available_years"
            v-if="adminToken"
          >
            <i class="fa-solid fa-gear"></i>
          </button>
        </div>
      </div>

      <div class="slideshow-buttons">
        <button
          v-for="(slide, index) of slides"
          @click="currentSlideIndex = index"
          :class="[{ active: currentSlideIndex == index }]"
        >
          {{ slide.name }}
        </button>

        <button
          class="slideshow-toggle"
          @click="slideshowOn = !slideshowOn"
          :class="[{ active: slideshowOn }]"
        >
          <span v-if="slideshowOn"><i class="fa-solid fa-pause"></i></span>
          <span v-else><i class="fa-solid fa-play"></i></span>
        </button>
      </div>

      <div v-if="stats">
        <Transition
          name="fade"
          mode="out-in"
          :duration="{ enter: 500, leave: 1 }"
        >
          <component
            :key="currentSlide.id"
            :is="currentSlide.component"
            :period="{ year: selectedYear, month: selectedMonth }"
            @updateTargets="getTargets"
            :targets="targets"
            :stats="
              stats && stats[`${currentSlide.key}`]
                ? stats[`${currentSlide.key}`]
                : null
            "
          ></component>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import { shallowRef } from "vue";
import { mapGetters } from "vuex";
import moment from "moment";
import TotalLTL from "../components/Consolidation/TotalLTL";
import TotalRPM from "../components/Consolidation/TotalRPM";
import WeeklyGross from "../components/Consolidation/WeeklyGross.vue";
import MonthlyTargets from "../components/Consolidation/MonthlyTargets.vue";

import Consolidation from "../mixins/Consolidation";
import ConsolidationSettingsUI from "../mixins/ConsolidationSettings/UI";

const SLIDESHOW_INTERVAL = 10000;

export default {
  mixins: [Consolidation, ConsolidationSettingsUI],
  components: {
    TotalLTL,
    TotalRPM,
    WeeklyGross,
    MonthlyTargets,
  },
  data() {
    return {
      slideshowOn: false,
      slideshowTimeout: null,
      settings: {
        date: moment().toDate(),
        year: 2024,
        month: "january",
      },
      stats: null,
      targets: null,
      currentSlideIndex: 0,
      slides: [
        {
          id: "monthly-targets",
          name: "Targets",
          key: "totalLtl",
          component: shallowRef(MonthlyTargets),
        },
        {
          id: "total-ltl",
          name: "Total LTL",
          key: "totalLtl",
          component: shallowRef(TotalLTL),
        },
        {
          id: "total-rpm",
          name: "Total RPM",
          key: "totalRpm",
          component: shallowRef(TotalRPM),
        },
        {
          id: "weekly-gross",
          name: "Total Gross",
          key: "weeklyGross",
          component: shallowRef(WeeklyGross),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["adminToken"]),
    currentSlide() {
      return this.slides[this.currentSlideIndex];
    },
    selectedYear() {
      const statsDate = moment(this.settings.date);
      return statsDate.year();
    },
    selectedMonth() {
      const statsDate = moment(this.settings.date);
      return statsDate.format("MMMM");
    },
  },
  watch: {
    "settings.date"() {
      this.getStats();
      this.getTargets();
    },
    slideshowOn() {
      if (!this.slideshowOn) {
        console.log("finishing slideshow...");
        clearInterval(this.slideshowTimeout);
      } else {
        this.startSlideshow();
      }
    },
  },
  methods: {
    startSlideshow() {
      this.slideshowTimeout = setInterval(() => {
        const nextIndex = (this.currentSlideIndex + 1) % this.slides.length;
        this.currentSlideIndex = nextIndex;
      }, SLIDESHOW_INTERVAL);
    },
    settingsSwitchMonth(val) {
      let date = moment(this.settings.date).startOf("month").add(val, "months");
      this.settings.date = date.toDate();
    },
    getTargets() {
      this.consolidation_get_targets(
        this.selectedYear,
        this.selectedMonth,
        (err, body) => {
          if (!err) this.targets = body;
        }
      );
    },
    getStats() {
      const statsDate = moment(this.settings.date);
      this.ajax(
        "Consolidation-GetStats",
        {
          method: "GET",
          url: `/consolidation/result/${statsDate.year()}/${statsDate.format(
            "MMMM"
          )}`,
        },
        (err, body) => {
          if (!err) {
            this.stats = body;
          }
        }
      );
    },
  },
  mounted() {
    this.getStats();
    this.getTargets();

    this.$WebSocket.on("open", "consolidation-main", () => {
      this.getStats();
      this.getTargets();
    });

    this.$WebSocket.on(
      "/consolidation/stats/update",
      "consolidation-main",
      () => {
        this.getStats();
      }
    );

    this.$WebSocket.on(
      "/consolidation/monthly-targets/update",
      "consolidation-main",
      () => {
        this.getTargets();
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.manage-btn {
  border: 0;
  background: $themeColor1;
  width: 36px;
  height: 36px;
  color: $text;
  box-shadow: $boxShadow;
  border-radius: 5px;
  border: 1px solid $borderColor;
  cursor: pointer;
  &:hover {
    color: $themeColor2;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from {
  opacity: 0;
}
.fade-leave-to {
  opacity: 1;
}

.target-month-switcher {
  display: grid;
  grid-template-columns: auto 150px auto;
  align-items: center;
  gap: 10px;
  background: $bgShade;
  padding: 5px 10px;
  box-shadow: $boxShadow;
  border-radius: 5px;
  border: 1px solid $borderColor;
  button.switch {
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 6px;
    color: $textLight;
    background: transparent;
    cursor: pointer;
    font-size: 12px;
    &:hover {
      color: $textShade;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.slideshow-buttons {
  display: flex;
  gap: 10px;
  button {
    background: $bgShade;
    height: 34px;
    padding: 0 15px;
    border-radius: 6px;
    border: 0;
    box-shadow: $boxShadow;
    font-size: 500;
    cursor: pointer;
    transition: ease 0.3s;
    border: 1px solid $borderColor;
    &:hover,
    &.active {
      background: $themeColor2;
      color: #fff;
    }
  }
  .slideshow-toggle {
    margin-left: auto;
    &.active {
      background: $success;
    }
  }
}
</style>
