<template>
  <TableRow :size="['40px', 'minmax(0, 1fr)', 'minmax(100px, 150px)', '100px']">
    <div>{{ index + 1 }}.</div>
    <div class="dispatcher-name">
      <a href="" @click.prevent=""
        ><span style="text-transform: capitalize">{{ item.name }}</span></a
      >
    </div>
    <div>
      <strong>{{ CURRENCY.format(item.amount) }}</strong>
    </div>
    <div>
      <a href="" @click.prevent="setTarget">{{
        targetRes
          ? CURRENCY.format(target.target)
          : adminToken
          ? "Set target"
          : "Not set"
      }}</a>
    </div>
  </TableRow>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["item", "index", "target"],
  methods: {
    setTarget() {
      if (!this.adminToken) return;
      this.$emit("setTarget", {
        user: this.item.name,
        target: this.target ? this.target.target : 0,
      });
    },
  },
  computed: {
    ...mapGetters(["adminToken"]),
    targetRes() {
      if (!this.target || !this.target.target) return null;
      try {
        const percent = (this.item.amount * 100) / this.target.target;
        return {
          percent,
        };
      } catch (e) {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.target-container {
  display: grid;
  gap: 10px;
  font-size: 14px;
  grid-template-columns: minmax(0, 1fr) 35px;
  align-items: center;
  cursor: pointer;
  position: relative;
  .--hover {
    position: absolute;
    bottom: 100%;
    left: 50%;
    // width: 120px;
    text-wrap: nowrap;
    font-weight: 500;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.9);
    z-index: 2;
    transform: translate(-50%, -10px);
    border-radius: 10px;
    padding: 5px;
    color: #fff;
    cursor: auto;
    text-align: center;
    display: none;
  }
  &:hover {
    .--hover {
      display: block;
    }
  }
  .bar-holder {
    width: 100%;
    height: 8px;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: $boxShadow;
    // border: 1px solid $borderColor;
    .bar {
      position: absolute;
      height: 100%;
      width: 0;
      background: $themeColor2;
      transition: ease 0.3s;
      border-radius: 10px;
    }
  }
  .target-percent {
    font-size: 13px;
  }
}
</style>
