<template>
  <div class="modal-wrapper">
    <Table
      v-if="details && details.data"
      :head="[
        { name: 'Month' },
        { name: 'Total LTL' },
        { name: 'Total RPM' },
        { name: 'Weekly Gross' },
      ]"
      :stickyHead="true"
      :size="[
        'minmax(0, 1fr)',
        'minmax(0, 1fr)',
        'minmax(0, 1fr)',
        'minmax(0, 1fr)',
      ]"
    >
      <TableRow
        :size="[
          'minmax(0, 1fr)',
          'minmax(0, 1fr)',
          'minmax(0, 1fr)',
          'minmax(0, 1fr)',
        ]"
        :key="month"
        v-for="month of APP_MONTHS"
      >
        <div style="text-transform: capitalize">{{ month }}</div>
        <div>
          <Input placeholder="A1:A2" v-model="details.data[month].totalLtl" />
        </div>
        <div>
          <Input placeholder="B1:B2" v-model="details.data[month].totalRpm" />
        </div>
        <div>
          <Input
            placeholder="C1:C2"
            v-model="details.data[month].weeklyGross"
          />
        </div>
      </TableRow>
    </Table>
  </div>
</template>

<script>
import Helper from "../../../mixins/ConsolidationSettings/Helper";
export default {
  mixins: [Helper],
  props: ["year", "modal"],
  data() {
    return {
      details: {},
      errors: {},
    };
  },
  methods: {
    async getDetails() {
      this.modal.processing(true);
      await this.consolidation_settings_get_year_details(
        this.year,
        (err, body) => {
          if (err) {
            this.modal.close();
          } else {
            this.details = body;
          }
        }
      );
      this.modal.processing(false);
    },
  },
  mounted() {
    this.getDetails();
    this.modal.onConfirm(async () => {
      this.modal.processing(true);
      await this.consolidation_settings_update_year_details(
        this.year,
        this.details.data,
        (err, body) => {
          if (err) {
            alert(body.message || "Something went wrong. Please, try again!");
          } else {
            this.modal.close();
          }
        }
      );
      this.modal.processing(false);
    });
  },
};
</script>

<style lang="scss" scoped></style>
