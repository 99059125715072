export default {
  methods: {
    consolidation_get_dispatchers_targets(year, month, callback) {
      this.ajax(
        "Consolidation-GetDispatchersTargets",
        {
          method: "GET",
          url: `/consolidation/dispatchers-targets/${year}/${month}`,
        },
        callback
      );
    },
    consolidation_set_dispatchers_target(year, month, user, target, callback) {
      this.ajax(
        "Consolidation-SetDispatchersTargets",
        {
          method: "PUT",
          url: `/consolidation/dispatchers-targets`,
          data: {
            dispatcher: user,
            target,
            year,
            month,
          },
        },
        callback
      );
    },
    consolidation_get_targets(year, month, callback) {
      this.ajax(
        "Consolidation-GetTargets",
        {
          method: "GET",
          url: `/consolidation/ltl-gross-target/${year}/${month}`,
        },
        callback
      );
    },
    consolidation_set_monthly_target(year, month, type, target, callback) {
      this.ajax(
        "Consolidation-SetMonthlyTargets",
        {
          method: "PUT",
          url: `/consolidation/monthly-targets/${type}`,
          data: {
            target,
            year,
            month,
          },
        },
        callback
      );
    },
  },
};
