<template>
  <div class="modal-wrapper">
    <Table
      :head="[
        { name: 'Year' },
        { name: 'Sheet Name' },
        { name: 'Sheet ID' },
        { name: '' },
      ]"
      :stickyHead="true"
      :size="['100px', 'minmax(100px, 150px)', 'minmax(0, 1fr)', '100px']"
    >
      <TableRow
        :size="['100px', 'minmax(100px, 150px)', 'minmax(0, 1fr)', '100px']"
        :key="item._id"
        v-for="(item, index) of list"
      >
        <div>
          <a href="" @click.prevent="">{{ item.year }}</a>
        </div>
        <div>{{ item.sheetName }}</div>
        <div>{{ item.sheetId }}</div>
        <button
          class="edit-btn"
          @click="ui_consolidation_settings_edit_year(item.year)"
        >
          Edit
        </button>
      </TableRow>
    </Table>
  </div>
</template>

<script>
import Helper from "../../../mixins/ConsolidationSettings/Helper";
import UI_EditYear from "../../../mixins/ConsolidationSettings/UI_EditYear";
import UI_AddYear from "../../../mixins/ConsolidationSettings/UI_AddYear";

export default {
  mixins: [Helper, UI_EditYear, UI_AddYear],
  props: ["modal"],
  data() {
    return {
      list: [],
    };
  },
  methods: {
    async getYears() {
      this.modal.processing(true);
      await this.consolidation_settings_get_years((err, body) => {
        if (!err) {
          this.list = body;
        }
      });
      this.modal.processing(false);
    },
  },
  mounted() {
    this.getYears();
    this.modal.onReopen(() => {
      this.getYears();
    });

    this.modal.onConfirm(() => {
      this.ui_consolidation_settings_add_year();
    });
  },
};
</script>

<style lang="scss" scoped>
.edit-btn {
  height: 34px;
  border: 0;
  background: transparent;
  color: $themeColor2;
  cursor: pointer;
  border-radius: 9px;
  transition: ease 0.3s;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
