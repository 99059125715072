<template>
  <div
    style="
      display: grid;
      gap: 30px;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      align-items: start;
    "
  >
    <DashboardItem title="Top">
      <template v-slot:main>
        <Table :head="tableHeader" :stickyHead="true" :size="tableSizes">
          <TransitionGroup name="list">
            <TableRow
              :size="tableSizes"
              :key="`${item.dispatcher}:${item['truck#']}`"
              v-for="item of listOfDispatchers.best"
            >
              <div class="dispatcher-name">
                <a href="" @click.prevent=""
                  ><span style="text-transform: capitalize">{{
                    item.dispatcher
                  }}</span></a
                >
              </div>
              <div class="truck-number">{{ item["truck#"] }}</div>
              <div>
                <strong>{{ CURRENCY.format(item.avg) }}</strong>
              </div>
              <div>{{ item.totalMiles || 0 }}</div>
            </TableRow>
          </TransitionGroup>
        </Table>
      </template>
    </DashboardItem>

    <DashboardItem title="Underdogs">
      <template v-slot:main>
        <Table :head="tableHeader" :stickyHead="true" :size="tableSizes">
          <TransitionGroup name="list">
            <TableRow
              :size="tableSizes"
              :key="`${item.dispatcher}:${item['truck#']}`"
              v-for="item of listOfDispatchers.worst"
            >
              <div class="dispatcher-name">
                <a href="" @click.prevent=""
                  ><span style="text-transform: capitalize">{{
                    item.dispatcher
                  }}</span></a
                >
              </div>
              <div class="truck-number">{{ item["truck#"] }}</div>
              <div>
                <strong>{{ CURRENCY.format(item.avg) }}</strong>
              </div>
              <div>{{ item.totalMiles || 0 }}</div>
            </TableRow>
          </TransitionGroup>
        </Table>
      </template>
    </DashboardItem>
  </div>
</template>

<script>
import DashboardItem from "../DashboardItem.vue";

export default {
  components: {
    DashboardItem,
  },
  props: ["stats"],
  data: {
    sortOption: "avg",
  },
  computed: {
    listOfDispatchers() {
      const arr = JSON.parse(JSON.stringify(this.stats.data)).sort(
        (a, b) => b.avg - a.avg
      );

      const middleIndex = Math.floor(arr.length / 2);
      const firstHalf = arr.slice(0, middleIndex);

      const secondHalf = arr.slice(middleIndex);
      secondHalf.sort((a, b) => a.avg - b.avg);

      return { best: firstHalf.slice(0, 10), worst: secondHalf.slice(0, 10) };
    },
    sortOptions() {
      return [
        { name: "Average", value: "avg" },
        ...this.weeks.map((item) => ({ name: item, value: item })),
      ];
    },
    weeks() {
      if (!this.stats || !this.stats.data || !this.stats.data[0]) return [];
      return Object.keys(this.stats.data[0].weeks);
    },
    weeksTableHeader() {
      return this.weeks.map((item) => ({ name: item }));
    },
    tableHeader() {
      return (
        [{ name: "Dispatcher" }, { name: "Truck" }]
          // .concat(this.weeksTableHeader)
          .concat([{ name: "Average" }, { name: "Total Miles" }])
      );
    },
    tableSizes() {
      return (
        ["minmax(0, 1fr)", "minmax(100px, 150px)"]
          // .concat(this.weeks.map((item) => "minmax(0, 1fr)"))
          .concat(["minmax(100px, 150px)", "100px"])
      );
    },
    sortedStats() {
      if (this.weeks.includes(this.sortOption)) {
        return this.stats.data.sort(
          (a, b) => b.weeks[this.sortOption] - a.weeks[this.sortOption]
        );
      }

      return this.stats.data.sort(
        (a, b) => b[this.sortOption] - a[this.sortOption]
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
